import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Dashboard = lazy(() => import("../views/Dashboard.js"));
const About = lazy(() => import("../views/About.js"));
const FlawedApproach = lazy(() => import("../views/FlawedApproach.js"));
const BetterWay = lazy(() => import("../views/BetterWay.js"));
const FourPercentRule = lazy(() => import("../views/FourPercentRule.js"));
const BondLadder = lazy(() => import("../views/BondLadder.js"));
const ResidualValues = lazy(() => import("../views/ResidualValues.js"));

const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/dashboard", exact: true, element: <Dashboard /> },
      { path: "/four-percent-rule", exact: true, element: <FourPercentRule /> },
      { path: "/bond-ladder", exact: true, element: <BondLadder /> },
      { path: "/residual-values", exact: true, element: <ResidualValues />},
      { path: "/about-us", exact: true, element: <About /> },
      { path: "/flawed-approach", exact: true, element: <FlawedApproach /> },
      { path: "/better-way", exact: true, element: <BetterWay /> },

      { path: "/alerts", exact: true, element: <Alerts /> },
      { path: "/badges", exact: true, element: <Badges /> },
      { path: "/buttons", exact: true, element: <Buttons /> },
      { path: "/cards", exact: true, element: <Cards /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },
    ],
  },
];

export default ThemeRoutes;
