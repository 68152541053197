import { createContext, useState, useContext, useCallback } from "react";
import { debounce } from "lodash";

const API_URL = '/api'

const DataContext = createContext({
    data: undefined,
    setData: () => console.error('DataContextProvider not loaded')
});
    
const calcApi = debounce((currentAge, retirementAge, planToAge, retirementIncome, growthRate, likelihoodOfSuccess, annuityModifier, cb) => {
    fetch(`${API_URL}/calc?currentAge=${currentAge}&retirementAge=${retirementAge}&planToAge=${planToAge}&retirementIncome=${retirementIncome}&growthRate=${growthRate}&likelihoodOfSuccess=${likelihoodOfSuccess}&annuityModifier=${annuityModifier}`)
      .then(res => res.json())
      .then(data => cb(data))
}, 1000)

export const DataContextProvider = ({ children }) => {
    const [data, setData] = useState(undefined);

    const compute = useCallback((currentAge, retirementAge, planToAge, _retirementIncome, _growthRate, _likelihoodOfSuccess, annuityModifier) => {
        if (!_retirementIncome || !_growthRate || !_likelihoodOfSuccess) {
            setData(undefined)
            return
        }
        setData('processing')
        calcApi(currentAge, retirementAge, planToAge, _retirementIncome, _growthRate, _likelihoodOfSuccess, annuityModifier, setData)
    }, [setData])

    const reset = useCallback(() => {
        return fetch(`${API_URL}/reset`)
    }, [])

    return (
        <DataContext.Provider value={{data, compute, reset}}>
            {children}
        </DataContext.Provider>
    );
}

export const useDataContext = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useDataContext must be used within a DataContextProvider');
    }
    return context;
}
